import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** ID of each project integration */
export enum ProjectIntegrationId {
  autodesk = "autodesk",
  procore = "procore",
  autodeskAccIssues = "autodesk-acc.issues",
  autodeskAccRfis = "autodesk-acc.rfis",
  autodeskBim360Issues = "autodesk-bim360.issues",
  autodeskBim360Rfis = "autodesk-bim360.rfis",
  procoreObservations = "procore.observations",
  procoreRfis = "procore.rfis",
}

/** Data of a legacy (HoloBuilder) integration connected to a project */
interface LegacyProjectIntegration {
  /** The project name as defined by the integration */
  projectName: string;

  /** The project Id as defined by the integration */
  projectId: string;
}

/** Data of the legacy (HoloBuilder) Autodesk integration connected to a project */
export interface AutodeskLegacyProjectIntegration extends LegacyProjectIntegration {
  /** Id of the hub given by Autodesk */
  hubId: string;

  /** Autodesk integration ID */
  providerId: SphereDashboardAPITypes.IntegrationId.autodesk;
}

/** Data of the legacy (HoloBuilder) Procore integration connected to a project */
export interface ProcoreLegacyProjectIntegration extends LegacyProjectIntegration {
  /** Unique identifier of a company given by Procore */
  companyId: string;

  /** Procore integration ID */
  providerId: SphereDashboardAPITypes.IntegrationId.procore;
}


/** Data of an integration connected to a project */
export interface ProjectIntegration {
  /** ID of the BCF integration */
  bcfProjectId: string | null;
}

/** Integrations object of the ProjectApi root metadata */
export type ProjectIntegrations = {
  /** Data of the legacy (HoloBuilder) Autodesk integration connected to a project */
  [ProjectIntegrationId.autodesk]?: AutodeskLegacyProjectIntegration | null;

  /** Data of the legacy (HoloBuilder) Procore integration connected to a project */
  [ProjectIntegrationId.procore]?: ProcoreLegacyProjectIntegration | null;

  /** Data of the ACC-issues integration connected to a project */
  [ProjectIntegrationId.autodeskAccIssues]?: ProjectIntegration | null;

  /** Data of the ACC-RFIs integration connected to a project */
  [ProjectIntegrationId.autodeskAccRfis]?: ProjectIntegration | null;

  /** Data of the Bim360-issues integration connected to a project */
  [ProjectIntegrationId.autodeskBim360Issues]?: ProjectIntegration | null;

  /** Data of the Bim360-RFIs integration connected to a project */
  [ProjectIntegrationId.autodeskBim360Rfis]?: ProjectIntegration | null;

  /** Data of the Procore-observations integration connected to a project */
  [ProjectIntegrationId.procoreObservations]?: ProjectIntegration | null;

  /** Data of the Procore-RFIs integration connected to a project */
  [ProjectIntegrationId.procoreRfis]?: ProjectIntegration | null;
};

/** Parsed message with the result of the integration authorization */
export interface AuthorizationMessage {
  /** Whether the connection was established. True if successful */
  // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by CoreAPI backend
  connectStatus: boolean;

  /** ID of the integration */
  providerId: SphereDashboardAPITypes.IntegrationId;

  /** Result status */
  status: "success" | "error";

  /** Human readable result message */
  message: string;
}
