import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useIntegrationsService } from "@hooks/use-integrations-service";
import { useToast } from "@hooks/use-toast";
import { Box, Button } from "@mui/material";
import { runtimeConfig } from "@src/runtime-config";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useCallback, useEffect, useMemo, useState } from "react";
import ComingSoonIcon from "@assets/icons/new/coming-soon_60px.svg?react";
import { EmptyPage } from "@components/common/empty-page/empty-page";
import { useAppSelector } from "@store/store-helper";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";

export function Integrations(): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const integrationsService = useIntegrationsService({
    coreApiClient,
    procoreApiUrl: runtimeConfig.integrations.procoreApiUrl,
  });
  const { handleErrorWithToast } = useErrorContext();
  const { showToast } = useToast();
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);

  const [tokens, setTokens] = useState<SphereDashboardAPITypes.IntegrationToken[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const procoreToken = useMemo(() => {
    return tokens.find((token) => token.provider === SphereDashboardAPITypes.IntegrationId.procore);
  }, [tokens]);

  const autodeskToken = useMemo(() => {
    return tokens.find((token) => token.provider === SphereDashboardAPITypes.IntegrationId.autodesk);
  }, [tokens]);


  const getIntegrationTokens = useCallback(async (): Promise<void> => {
    try {
      const integrationTokens = await integrationsService.getIntegrationTokens();
      setTokens(integrationTokens);
    } catch (error) {
      handleErrorWithToast({
        id: `getIntegrationTokens-${Date.now().toString()}`,
        title: "Failed to get the integration tokens.",
        error,
      });
    }
  }, [handleErrorWithToast, integrationsService]);

  const enableIntegration = useCallback(async (integrationId: SphereDashboardAPITypes.IntegrationId): Promise<void> => {
    setIsDialogOpen(true);

    try {
      const integrationTokens = await integrationsService.authorizeIntegration(integrationId);
      setTokens(integrationTokens);
      showToast({
        message: "Integration enabled",
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `enableIntegration-${Date.now().toString()}`,
        title: "Failed to enable the integration.",
        error,
      });
    }

    setIsDialogOpen(false);
  }, [handleErrorWithToast, integrationsService, showToast]);

  const disableIntegration = useCallback(async (integrationId: SphereDashboardAPITypes.IntegrationId): Promise<void> => {
    try {
      const integrationTokens = await integrationsService.revokeAuthorization(integrationId);
      setTokens(integrationTokens);
      showToast({
        message: "Integration disabled",
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `disableIntegration-${Date.now().toString()}`,
        title: "Failed to disable the integration.",
        error,
      });
    }
  }, [handleErrorWithToast, integrationsService, showToast]);

  const closeAuthorizationWindow = useCallback(() => {
    integrationsService.closeAuthorizationWindow();
    setIsDialogOpen(false);
  }, [integrationsService]);

  useEffect(() => {
    getIntegrationTokens();
  }, [getIntegrationTokens]);

  if (!isAlphaTestingEnabled) {
    return (
      <EmptyPage
        title="Coming soon"
        subtitle="This page is still under development and will be available soon"
        icon={ComingSoonIcon}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        mt: "20px",
      }}
    >
      <FaroDialog
        open={isDialogOpen}
        onConfirm={() => closeAuthorizationWindow()}
        confirmText="Cancel authorization"
        title="Integration authorization is in progress"
        isSuccessMessage
      >
        Complete the authorization in the opened window
      </FaroDialog>
      <Box>
        Procore
        <Box>
          {`Is enabled: ${procoreToken !== undefined ? "Yes": "No"}`}
        </Box>
        <Button
          disabled={procoreToken !== undefined}
          onClick={() => enableIntegration(SphereDashboardAPITypes.IntegrationId.procore)}
        >
          Enable
        </Button>
        <Button
          disabled={procoreToken === undefined}
          onClick={() => disableIntegration(SphereDashboardAPITypes.IntegrationId.procore)}
        >
          Disable
        </Button>
      </Box>
      <Box>
        Autodesk
        <Box>
          {`Is enabled: ${autodeskToken !== undefined ? "Yes": "No"}`}
        </Box>
        <Button
          disabled={autodeskToken !== undefined}
          onClick={() => enableIntegration(SphereDashboardAPITypes.IntegrationId.autodesk)}
        >
          Enable
        </Button>
        <Button
          disabled={autodeskToken === undefined}
          onClick={() => disableIntegration(SphereDashboardAPITypes.IntegrationId.autodesk)}
        >
          Disable
        </Button>
      </Box>
    </Box>
  );
}