import { ProjectApi } from "@api/project-api/project-api";
import {
  ApiClient,
  CoreAPIUtils,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import {
  ProjectIntegrationId,
  ProjectIntegrations,
} from "@src/services/integrations-service/integrations-types";
import {
  isAuthorizationMessage,
  isCanceledAuthorizationMessage,
  isIntegrationToken,
  isProjectIntegrations,
} from "@src/services/integrations-service/integrations-type-guards";
import { INTEGRATION_SCOPES } from "@services/integrations-service/integrations-constants";
import { createMutationSetElementMetaData } from "@faro-lotv/service-wires";
import { StatusCodes } from "http-status-codes";

export interface IntegrationsServiceProps {
  /** Core API client instance */
  coreApiClient: ApiClient;
  /** Project API client instance */
  projectApiClient?: ProjectApi;
  /** URL of the Procore API */
  procoreApiUrl: string;
}

/** Service that provides methods to manage 3rd party integrations with Sphere XG */
export class IntegrationsService {
  #coreApiClient: ApiClient;
  #projectApiClient: ProjectApi | undefined;
  #procoreApiUrl: string;
  #authorizationWindow: Window | null = null;
  #onAuthorizationMessage: (messageEvent: MessageEvent) => void = () => undefined;

  constructor({ coreApiClient, projectApiClient, procoreApiUrl }: IntegrationsServiceProps) {
    this.#coreApiClient = coreApiClient;
    this.#projectApiClient = projectApiClient;
    this.#procoreApiUrl = procoreApiUrl;
  }

  /**
   * @returns True if the authorization window is open
   */
  private get isAuthorizationWindowOpen(): boolean {
    return this.#authorizationWindow !== null && !this.#authorizationWindow.closed;
  }

  /**
   * @returns The user tokens of the authorized integrations
   * @throws {CoreAPITypes.IResponseError} if it fails to fetch the user tokens from the Core API backend
   */
  public async getIntegrationTokens(): Promise<SphereDashboardAPITypes.IntegrationToken[]> {
    const tokens = await this.#coreApiClient.V1.SDB.getIntegrationTokens();
    return tokens.filter(isIntegrationToken);
  }

  /**
   * Opens the page for the user to authorize Sphere XG to connect to an integration, which will set a token in CoreAPI
   * @returns The updated list of integration tokens
   * @param integrationId ID of the integration to authorize
   * @throws {Error} if another integration authorization is already in progress or if it fails the get a successful response
   * @throws {CoreAPITypes.IResponseError} if it fails to fetch the user token from the Core API backend
   */
  public async authorizeIntegration(
    integrationId: SphereDashboardAPITypes.IntegrationId
  ): Promise<SphereDashboardAPITypes.IntegrationToken[]> {
    if (this.isAuthorizationWindowOpen) {
      // eslint-disable-next-line max-len -- error message
      throw new Error("Another integration authorization is in progress. Please finish the current authorization before authorizing another integration.");
    }

    const url = new URL("/v1/users/tokens/connect", this.#coreApiClient.options.url);
    url.searchParams.set("providerId", integrationId);
    if (INTEGRATION_SCOPES[integrationId].length > 0) {
      INTEGRATION_SCOPES[integrationId].forEach((scope) => {
        url.searchParams.append("scopes", scope);
      });
    }

    this.#authorizationWindow = window.open(url.href, "_blank");

    if (!this.isAuthorizationWindowOpen) {
      throw new Error("Could not open integration authorization window.");
    }

    await this.waitForAuthorization();
    this.closeAuthorizationWindow();
    return await this.getIntegrationTokens();
  }

  /**
   * Revokes an integration token
   * @param integrationId ID of the integration
   * @returns The update list of the integration tokens
   * @throws {CoreAPITypes.IResponseError} if it fails to revoke the token or get the tokens from the Core API backend
   */
  public async revokeAuthorization(
    integrationId: SphereDashboardAPITypes.IntegrationId
  ): Promise<SphereDashboardAPITypes.IntegrationToken[]> {
    try {
      return await this.#coreApiClient.V1.SDB.revokeIntegrationToken(integrationId);
    } catch (error) {
      // The CoreAPI throws an error status 404 if the token is not found because it was already revoked
      // In this case just return the tokens
      if (CoreAPIUtils.isResponseError(error) && error.status === StatusCodes.NOT_FOUND) {
        return await this.getIntegrationTokens();
      }
      throw error;
    }
  }

  /**
   * Closes the authorization window and removes the window event listener for the authorization message
   */
  public closeAuthorizationWindow(): void {
    window.removeEventListener("message", this.#onAuthorizationMessage);
    this.#authorizationWindow?.close();
    this.#authorizationWindow = null;
  }

  /**
   * Adds an event listener for window messages and waits for the authorization result message
   */
  private waitForAuthorization(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.#onAuthorizationMessage = (messageEvent: MessageEvent): void => {
        if (
          !messageEvent.source ||
          !this.#authorizationWindow ||
          messageEvent.source !== this.#authorizationWindow ||
          messageEvent.origin !== this.#coreApiClient.options.url
        ) {
          return;
        }
    
        // For a canceled message CoreAPI sends the data as a string.
        if (isCanceledAuthorizationMessage(messageEvent.data)) {
          this.closeAuthorizationWindow();
          return reject(new Error("The integration authorization has been canceled."));
        }
    
        // For a success or error message CoreAPI sends the JSON data stringified. We have to parse it before validating the type.
        let parsedMessage;
        try {
          parsedMessage = JSON.parse(messageEvent.data);
        } catch (_) {
          this.closeAuthorizationWindow();
          return reject(new Error(`Authorization message parse JSON failed for message ${messageEvent.data}`));
        }
    
        if (!isAuthorizationMessage(parsedMessage)) {
          this.closeAuthorizationWindow();
          return reject(new Error("Authorization message does not have the expected data type"));
        }
    
        if (parsedMessage.status === "error" || parsedMessage.connectStatus === false) {
          this.closeAuthorizationWindow();
          return reject(new Error(parsedMessage.message));
        }
    
        if (parsedMessage.status === "success" && parsedMessage.connectStatus === true) {
          this.closeAuthorizationWindow();
          return resolve();
        }
      };

      window.addEventListener("message", this.#onAuthorizationMessage);
    });
  }

  /**
   * @returns The ProjectIntegrations entity of the current project
   * @throws {Error} if the Project Api client is not defined
   * @throws {ProjectApiError} if it fails to fetch data from Project Api
   */
  public async getProjectIntegrations(): Promise<ProjectIntegrations | undefined> {
    if (!this.#projectApiClient) {
      throw Error("ProjectApi client is not defined");
    }

    const root = await this.#projectApiClient.getRootIElement();
    const projectIntegrations = root.metaDataMap?.projectIntegrations;

    if (!projectIntegrations) {
      return;
    }

    if (!isProjectIntegrations(projectIntegrations)) {
      throw Error("Failed to validate the project integrations object");
    }

    return projectIntegrations;
  }

  /**
   * Disconnects an integration from a project.
   * To achieve this it sets each project integration value to null for each user level integration
   * @returns The updated ProjectIntegrations entity
   * @param integrationId ID of the integration to disconnect
   * @throws {Error} if the Project Api client is not defined
   * @throws {ProjectApiError} if it fails to update or fetch data from Project Api
   */
  public async disconnectProject(
    integrationId: SphereDashboardAPITypes.IntegrationId
  ): Promise<ProjectIntegrations | undefined> {
    const projectIntegrations = await this.getProjectIntegrations();

    if (!projectIntegrations) {
      return;
    }

    switch (integrationId) {
      case SphereDashboardAPITypes.IntegrationId.procore:
        projectIntegrations[ProjectIntegrationId.procore] = null;
        projectIntegrations[ProjectIntegrationId.procoreObservations] = null;
        projectIntegrations[ProjectIntegrationId.procoreRfis] = null;
        break;
      case SphereDashboardAPITypes.IntegrationId.autodesk:
        projectIntegrations[ProjectIntegrationId.autodesk] = null;
        projectIntegrations[ProjectIntegrationId.autodeskAccIssues] = null;
        projectIntegrations[ProjectIntegrationId.autodeskAccRfis] = null;
        projectIntegrations[ProjectIntegrationId.autodeskBim360Issues] = null;
        projectIntegrations[ProjectIntegrationId.autodeskBim360Issues] = null;
        break;
    }

    return await this.setProjectIntegrations(projectIntegrations);
  }

  /**
   * Stores the ProjectIntegrations entity in the metadata of the ProjectAPI root IElement
   * @param projectIntegrations ProjectIntegrations entity
   * @returns the updated ProjectIntegrations entity
   * @throws {Error} if the Project Api client is not defined
   * @throws {ProjectApiError} if it fails to update or fetch data from Project Api
   */
  private async setProjectIntegrations(
    projectIntegrations: ProjectIntegrations
  ): Promise<ProjectIntegrations> {
    if (!this.#projectApiClient) {
      throw Error("ProjectApi client is not defined");
    }

    const { id } = await this.#projectApiClient.getRootIElement();

    const mutation = createMutationSetElementMetaData(id, [
      {
        key: "ProjectIntegrations",
        // The ProjectApi client request to convert the value to a JSON string if it's an object but this
        // actually does not work. But actually the ProjectApi backend does accept an object as value.
        // That's why we have this workaround to assert the value as string.
        value: projectIntegrations as string,
        // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by Project Api backend
        skipIfPresent: false,
      },
    ]);

    await this.#projectApiClient.applyMutations([mutation]);
    const updatedProjectIntegrations = await this.getProjectIntegrations();

    if (!updatedProjectIntegrations) {
      throw Error("Integrations were updated for the project but they were later not available in the backend.");
    }

    return updatedProjectIntegrations;
  }
}
